<template>
	<div class="searchinput">
		<div action  class="search">
			<img src="@/assets/common/icon_search.png">
			<input
				@keyup.enter="searchEdit"
				type="text"
				:placeholder="placeholder"
				v-model="searchContent"
			/>
		</div>
	</div>
</template>

<script>

export default {
	name: 'SearchInput',
	props: {
		placeholder: String,
		search: String
	},
	data() {
		return {
			searchContent: ''
		};
	},
	watch: {
		search(e) {
			this.searchContent = this.search
		}
	},
	methods: {
		// 搜索修改
		searchEdit(e) {
			this.$emit('edit', this.searchContent)
		}
	}
}
</script>

<style lang="scss" scoped>
.searchinput{
	padding: 30px 0;
	background-color: #171717;
}
.search{
	margin: 0 auto;
	width: 670px;
	height: 80px;
	display: flex;
	align-items: center;
	background: #2B2B2B;
	img{
		margin: 0 24px;
		width: 32px;
		height: 32px;
	}
	input::-webkit-input-placeholder{
		font-size:28px;  
	} 
	input{
		width: 570px;
		height: 40px;
		font-size: 32px;
		line-height: 40px;
		font-family: Regular;
		border: none;
		color: #F4F4F4;
		background-color: rgba(0,0,0,0);
	}
}
</style>