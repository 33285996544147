<template>
	<div class="menus">
		<div class="menu" v-for="item in list" :key="item.value" @click="menuEdit(item)">
			<img class="menu__logo" :src="item.logo" mode="widthFix">
			<div class="menu__name">{{ item.name }}</div>
			<div class="menu__redpoint" v-if="item.redpoint"></div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'MenuCard',
	props: {
		list: Array
	},
	data() {
		return {

		};
	},
	methods: {
		// 菜单切换
		menuEdit(e) {
			this.$emit('edit', e.value)
		}
	}
}
</script>

<style lang="scss" scoped>
.menus {
	padding: 40px 40px 0;
	width: 100%;
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.menu {
	position: relative;
	width: 136px;
	border: 1px solid rgba(255, 255, 255, 0.3);
	border-radius: 8px;
	text-align: center;
	padding: 10px 0;
	margin-right: 90px;
	margin-bottom: 40px;

	&__logo {
		width: 48px;
	}

	&__name {
		line-height: 30px;
		font-size: 26px;
		font-family: HYQiHeiY3-55;
		color: #fff;
	}

	&__redpoint {
		position: absolute;
		background: red;
		right: 20px;
		top: 20px;
		width: 10px;
		height: 10px;
		border-radius: 50%;
	}
}

.menu:nth-child(3n) {
	margin-right: 0;
}
</style>