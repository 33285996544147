<template>
	<div class="home">
		<!-- 导航栏 -->
		<van-nav-bar
		class="isnav_bar_colors"
		:class="{'bagtop3':scoll>100}"
		title="试乘试驾"
		fixed
		z-index="11111" 
		:border="false"
		
		>
	</van-nav-bar>
		<!-- 头部 -->
		<div class="head" :class="{'bacg1':trialInfo.number == '','bacg2':trialInfo.number != ''}">
			
			<!-- 创建 -->
			<div class="create" v-if="trialInfo.number == ''">
				<div class="create__phone">
					<input
						class="create__phone__input"
						placeholder-style="font-family: HYQiHeiY3-45; color: #F4F4F4;"
						type="number"
						maxlength="11"
						placeholder="请输入客户电话"
						v-model="scsj.phone"
					/>
				</div>
				<div class="create__btn" @click="handCreateScsj">
					创建试乘试驾
				</div>
			</div>
			
			<!-- 当前试乘试驾 -->
			<div class="info" v-if="trialInfo.number != ''">
				<div class="info__title">
					有一个试乘试驾正在进行中
				</div>
				<div class="info__row">
					{{trialInfo.name}} {{trialInfo.mobile}}
				</div>
				<div class="info__row" style="margin-bottom: 60px;">
					{{'凯迪拉克'}} {{trialInfo.model_name}}
				</div>
				<div class="info__btn" @click="handTrial(trialInfo)">
					进入
				</div>
			</div>
		</div>
		<van-sticky @scroll="changetop" :offset-top="40">
		<!-- 卡片菜单 -->
		<MenuCard style="background-color: #191919 ;" :list="menuCardList" v-on:edit="handMenuCard"></MenuCard>
		
<!-- tab菜单 -->
		<!-- tab菜单 -->
		<!-- <div id="demo" :style="{'top': navBarHeight + 'px','padding':'30px 0 0'}" :class="{'isfixed':isfixed}"> -->
			
			
				<MenuTab :list="menuTabList" :listChecked="menuTabChecked" v-on:edit="handMenuTab"></MenuTab>
			
			<!-- 搜索 -->
				<SearchInput
				:placeholder="searchInputPlaceholder"
				:search="searchInputValue"
				v-on:edit="handSearchInput"
			></SearchInput>
		</van-sticky>

			<!-- 试驾列表 -->
			<van-list
				v-model="loading"
				style="font-family:HYQiHeiY3-55;"
				:finished="finished"
				finished-text="没有更多了"
				@load="onLoad"
			>
				<div v-show="menuTabChecked == 'list'" class="scsjs">
					<div class="scsj" v-for="item,index in scsjList" :key="index" @click="handTrial(item)">
						<div class="scsj__left">
							<div class="scsj__user">
								{{item.name}} {{item.mobile}}
							</div>
							<div class="scsj__time">
								{{item.finishtime}}
							</div>
							<div class="scsj__staffs">
								<div class="scsj__staffs__staff" v-for="itemUser in item.user" :key="itemUser.id">
									<van-image class="img_avatar" round :src="itemUser.avatar" fit="cover"/>
									<span>{{itemUser.username}}</span>
								</div>
							</div>
						</div>
						<div class="scsj__right">
							<img src="@/assets/common/icon_right.png">
						</div>
						<div class="scsj__suprcruise" v-if="item.if_cruise == 1">
							<img src="@/assets/common/icon_suprcruise.png">
						</div>
					</div>
				</div>
				<div v-if="scsjList.length == 0 && menuTabChecked == 'list'" class="custom-image"><img src="@/assets/user_edit/icon_kong.png" alt=""><div>暂无数据</div></div>
			
			<!-- 邀请列表 -->
				<div v-show="menuTabChecked == 'inviter'" class="inviters">
					<div class="fixed" @click="goInvitation">
						全部邀请函
					</div>
					<div class="inviter" v-for="item,index in inviterList" :key="index" @click="handInviter(item)">
						<div class="inviter__left">
							<div class="inviter__user">
								<span>{{item.customer_name}}</span><span>{{item.customer_mobile}}</span>
							</div>
							<div class="inviter__car">
								凯迪拉克 {{item.model_name}}
							</div>
							<div class="inviter__time">
								{{item.appointment_time}}
								<div v-if="item.if_check == 1" class="inviter__time__botton">
									已确认
								</div>
							</div>
						</div>
						<div class="inviter__right">
							<img src="@/assets/common/icon_right.png">
						</div>
					</div>
				</div>
				<div v-if="inviterList.length == 0 && menuTabChecked == 'inviter'" class="custom-image"><img src="@/assets/user_edit/icon_kong.png" alt=""><div>暂无数据</div></div>

			</van-list>
		
		<van-popup v-model="show" position="bottom" :style="{ height: '25%',backgroundColor:'#575757'}" >
			<div class="popup-content">
				<div class="invitation-menus">
					<div class="invitation-menu2" @click="enterInvitationList('invitation_letter')">
						<img class="invitation-menu2__logo" src="@/assets/invitation/invitation_letter.png" mode="widthFix">
						<div class="invitation-menu2__name">邀请函</div>
					</div>
					<div class="invitation-menu2" @click="enterInvitationList('thank_letter')">
						<img class="invitation-menu2__logo" src="@/assets/invitation/thank_letter.png" mode="widthFix">
						<div class="invitation-menu2__name">致谢函</div>
					</div>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
import MenuCard from '@/components/menucard.vue'
import MenuTab from '@/components/menutab.vue'
import SearchInput from '@/components/searchinput.vue'
import { getDateTime2, getDateTime3 } from '@/utils/utils.js'
	
export default {
	components: {
		MenuCard,
		MenuTab,
		SearchInput
	},
	data() {
		return {
			scrollTop: 0,
			scrollHeight: '600px',
			isFixed:false,
			scsj: {
				phone: '',
			},
			
			menuCardList: [{
				logo: require('../../assets/common/icon_reservation.png'),
				name: '预约管理',
				value: 'reservation',
				redpoint: false
			}, {
				logo: require('../../assets/common/icon_inviter.png'),
				name: '发送邀请',
				value: 'inviter',
				redpoint: false
			}, {
				logo: require('../../assets/common/icon_news.png'),
				name: '消息中心',
				value: 'news',
				redpoint: false
			}],
			menuTabList: [{
				num: 0,
				name: '试驾记录',
				value: 'list'
			}, {
				num: 0,
				name: '试驾邀请',
				value: 'inviter'
			}],
			menuTabChecked: 'list',
			
			searchInputValue: '',
			scsjList: [],
			scsjStatus: 'more',
			scsjPage: 1,
			scsjLimit: 10,
			scsjTotal: 0,
			inviterList: [],
			inviterStatus: 'more',
			inviterPage: 1,
			inviterLimit: 10,
			inviterTotal: 0,
			
			trialInfo: {
				number: '',
			},
			// 分页
			loading: false,
      finished: false,
			scoll:0,
			// 底部弹框
			show:false,
		}
	},
	computed: {
		searchInputPlaceholder() {
			return {
				list: '请输入客户名称、电话查找试驾记录',
				inviter: '请输入客户名称、电话查找试驾邀请'
			}[this.menuTabChecked]
		}
	},
	mounted() {
		this.getScsjMine()
		this.getScsjList()
		this.getInviterList()
		this.searchInputValue = ''
		this.getMessageNum()
		localStorage.removeItem('fromshijia')
		localStorage.removeItem('timemodel')
	},
	methods: {
		isToday(str){
			let d = new Date(parseInt(str *1000)).setHours(0, 0, 0, 0);
			
			let today = new Date().setHours(0, 0, 0, 0);
			let obj = {
				0: '今日',
				86400000: '明日',
			};
			return obj[d - today] || false;
		},
		changetop(e,v){
			this.scoll = e.scrollTop
			this.isFixed = e,v
		},
		async onLoad(){
			if(this.menuTabChecked == 'list'){
				if(this.scsjPage == 1) return this.loading = false;
				const {data: result} = await this.$http.post('/api/scsj/store_scsj_log_1_1', {
				page: this.scsjPage,
				num: this.scsjLimit,
				words: this.searchInputValue,
				store_id:0
			})
				if (result.code == 1) {
					this.scsjPage=this.scsjPage+1
					this.scsjTotal = result.data.total
					this.menuTabList[0].num = this.scsjTotal
					result.data.res.forEach(v => {
						v.finishtime = getDateTime2(v.finishtime)
						v.user.forEach(vu => {
							vu.avatar = this.serverUrl(vu.avatar)
						})
						this.scsjList.push(v)
					})
				}
			 // 加载状态结束
			 this.loading = false;
			 // 数据全部加载完成
			 if (this.scsjList.length >= this.scsjTotal) {
					this.finished = true;
				}
			}else{
				if(this.inviterPage == 1) return this.loading = false;
					const {data: result} = await this.$http.post('/api/scsj/get_store_invitation_list_1_7', {
					page: this.inviterPage,
					number: this.inviterLimit,
					type_id: 1,
					words: this.searchInputValue
				})
				if (result.code == 1) {
					this.inviterPage=this.inviterPage+1
					this.inviterTotal = result.data.total
					this.menuTabList[1].num = this.inviterTotal
					result.data.res.forEach(v => {
						const ss = this.isToday(v.appointment_time)
						if(ss){
							v.appointment_time = getDateTime3(v.appointment_time)
							v.appointment_time = v.appointment_time.slice(v.appointment_time.indexOf(' '))
							v.appointment_time = ss + ' ' + v.appointment_time
						}else{
							v.appointment_time = getDateTime3(v.appointment_time)
						}
						this.inviterList.push(v)
					})
				}
				// 加载状态结束
				this.loading = false;
			 // 数据全部加载完成
			 if (this.inviterList.length >= this.inviterTotal) {
					this.finished = true;
				}
			}
		},
		// 试驾详情
		handTrial(e) {
			this.$router.push({
				path: `/trial?number=${e.number}`
			})
		},
		
		// 邀请函列表
		goInvitation(){
			localStorage.removeItem('formactive')
			this.$router.push({
				path: '/Invitation-list'
			})
		},
		
		// 试驾邀请
		handInviter(e) {
			this.$router.push({
				path: `/trial-invitation?id=${e.id}`
			})
		},
		
		// 邀请函
		enterInvitationList(type){
			localStorage.removeItem('Invitationtype')
			this.$router.push({
				path: '/invitation?type='+type
			})
		},
		
		// 创建试乘试驾
		handCreateScsj(e) {
			if (this.scsj.phone.length < 5 || this.scsj.phone.length > 11) {
				this.$toast.fail('请输入正确电话')
				return
			}
			
			this.$router.push({
				path: `/trial-add?phone=${this.scsj.phone}`
			})
		},
		
		// 点击卡片菜单
		handMenuCard(value) {
			if(value == 'reservation'){
				this.$router.push({
					path: '/reservation'
				})
			}
			if(value == 'news'){
				this.$router.push({
					path: '/message'
				})
			}
			if(value == 'inviter'){
				this.show = true
			}
		},
		
		// 点击tab菜单
		handMenuTab(e) {
			this.menuTabChecked = e
			this.inviterPage = 1
			this.scsjPage = 1
			this.inviterStatus = 'more'
			this.scsjList = []
			this.inviterList = []
			this.finished = false
			if(this.menuTabChecked == 'list'){
				this.getScsjList()
			}else{
				this.getInviterList()
			}
		},
		
		// 搜索内容修改
		handSearchInput(e) {
			this.searchInputValue = e.trim()
			if (this.searchInputValue.length == 0) return
			this.$router.push({
				path: `/search?type=${this.menuTabChecked}&words=${this.searchInputValue}&categories=all`
			})
		},
		
		// 试驾记录
		async getScsjList(e) {
			const {data: result} = await this.$http.post('/api/scsj/store_scsj_log_1_1', {
				page: this.scsjPage,
				num: this.scsjLimit
			})
			if (result.code == 1) {
				this.scsjPage=this.scsjPage+1
				this.scsjTotal = result.data.total
				this.menuTabList[0].num = this.scsjTotal
				result.data.res.forEach(v => {
					v.finishtime = getDateTime2(v.finishtime)
					v.user.forEach(vu => {
						vu.avatar = this.serverUrl(vu.avatar)
					})
					this.scsjList.push(v)
				})
				this.scsjStatus = 'more'
			}
		},
		
		// 邀请记录
		async getInviterList(e) {
			const {data: result} = await this.$http.post('/api/scsj/get_store_invitation_list_1_7', {
				page: this.inviterPage,
				number: this.inviterLimit,
				type_id: 1
			})
			if (result.code == 1) {
				this.InviterPage=this.InviterPage+1
				this.inviterTotal = result.data.total
				this.menuTabList[1].num = this.inviterTotal
				result.data.res.forEach(v => {
					const ss = this.isToday(v.appointment_time)
						if(ss){
							v.appointment_time = getDateTime3(v.appointment_time)
							v.appointment_time = v.appointment_time.slice(v.appointment_time.indexOf(' '))
							v.appointment_time = ss + ' ' + v.appointment_time
						}else{
							v.appointment_time = getDateTime3(v.appointment_time)
						}
					this.inviterList.push(v)
				})
			}
		},
		// 我的试乘试驾
		async getScsjMine(e) {
			const {data: result} = await this.$http.post('/api/scsj/user_scsj_status_1_1', {})
			if (result.code == 1) {
				this.trialInfo = {...this.trialInfo, ...result.data.res}
			}
		},
		
		// 我的消息数量
		async getMessageNum(e) {
			const {data: result} = await this.$http.post('/api/scsj/get_message_count', {})
			if (result.code == 1) {
				this.menuCardList[2].redpoint = result.data.res > 0
			}
		},
		
		// 试驾邀请
		
		// 预约管理
		
		// 发送邀请
		
		// 消息中心
	}
}
</script>

<style lang="scss">
.home{
	padding-bottom: 120px;

.head{
	width: 750px;
	height: 682px;
	position: relative;
}
.create{
	position: absolute;
	left: 0;
	bottom: 20px;
	width: 100%;
	&__phone{
		margin: 0 auto 40px;
		width: 492px;
		height: 88px;
		border: 1px solid rgba(255, 255, 255, 0.5);
		&__input{
			border: none;
			height: 88px;
			width: 100%;
			background-color: rgba(255, 255, 255, 0);
			font-size: 32px;
			font-family: Regular;
			color: #F4F4F4;
			text-align: center;
		}
	}
	&__btn{
		margin: 0 auto;
		width: 492px;
		line-height: 88px;
		background: #fa0037;
		font-size: 32px;
		font-family: HYQiHeiY3-45;
		color: #F4F4F4;
		text-align: center;
	}
}
.info{
	position: absolute;
	left: 0;
	bottom: 76px;
	width: 100%;
	&__title{
		margin: 0 auto 40px;
		width: 530px;
		font-size: 44px;
		font-family: HYQiHeiY3-55;
		color: #F3C846;
		line-height: 48px;
	}
	&__row{
		margin: 0 auto 20px;
		width: 530px;
		font-size: 32px;
		font-family: HYQiHeiY3-45;
		color: #9B9B9B;
		line-height: 34px;
	}
	&__btn{
		margin: 0 auto;
		width: 530px;
		line-height: 88px;
		background: #fa0037;
		font-size: 32px;
		font-family: HYQiHeiY3-65;
		color: #F4F4F4;
		text-align: center;
	}
}
.scsjs{
	width: 100%;
	box-sizing: border-box;
	padding: 0 32px 30px;
	.scsj{
		position: relative;
		margin-bottom: 30px;
		padding: 20px 32px;
		box-sizing: border-box;
		width: 100%;
		background: #373C46;
		border-radius: 12px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		&__user{
			font-size: 36px;
			font-family: HYQiHeiY3-45;
			color: #9B9B9B;
			line-height: 36px;
		}
		&__time{
			margin-top: 15px;
			font-size: 28px;
			font-family: HYQiHeiY3-45;
			color: #fff;
			line-height: 28px;
		}
		&__staffs{
			margin-top: 15px;
			display: flex;
			&__staff{
				font-size: 26px;
				font-family: HYQiHeiY3-45;
				color: #ECECEC;
				height: 40px;
				display: flex;
				align-items: center;
				margin-right: 20px;
				.img_avatar{
					width: 40px;
					height: 40px;
					border-radius: 50%;
					margin-right: 10px;
				}
			}
		}
		&__right{
			img{
				width: 40px;
				height: 32px;
			}
		}
		&__suprcruise{
			position: absolute;
			right: 0;
			top: 0;
			img{
				width: 118px;
				height: 118px;
			}
		}
	}
	.scsj:last-child{
		margin-bottom: 0;
	}
}
.inviters{
	box-sizing: border-box;
	width: 100%;
	padding: 0 32px 30px;
	.inviter{
		margin-bottom: 30px;
		padding: 20px 32px;
		width: 100%;
		box-sizing: border-box;
		background: #373C46;
		border-radius: 12px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		&__user{
			font-size: 36px;
			font-family: HYQiHeiY3-45;
			color: #9B9B9B;
			line-height: 36px;
			text:first-child{
				color: #fff;
				margin-right: 10px;
			}
		}
		&__car{
			margin-top: 15px;
			font-size: 28px;
			font-family: HYQiHeiY3-45;
			color: #fff;
			line-height: 28px;
		}
		&__time{
			display: flex;
			align-items: center;
			margin-top: 15px;
			font-size: 28px;
			font-family: HYQiHeiY3-45;
			color: #fff;
			line-height: 28px;
			&__botton{
				margin-left: 36px;
				width: 104px;
				height: 48px;
				background: #C8002B;
				border-radius: 8px;
				line-height: 48px;
				text-align: center;
				font-size: 26px;
				font-family: HYQiHeiY3-45;
				font-weight: normal;
				color: #ECECEC;
			}
		}
		&__right{
			img{
				width: 40px;
				height: 32px;
			}
		}
	}
	.inviter:last-child{
		margin-bottom: 0;
	}
}
.invitation-menus{
	box-sizing: border-box;
	padding: 40px 80px 0;
	width: 100%;
	display: flex;
	// flex-wrap: wrap;
	justify-content: space-around;
}
.invitation-menu2{
	box-sizing: border-box;
	// width: 49%;
	// border: 1px solid rgba(255, 255, 255, 0.3);
	border-radius: 8px;
	text-align: center;
	padding: 10px 0;
	background-color: none !important;
	// margin-right: 90px;
	margin-bottom: 10px;
	&__logo{
		width: 88px;
	}
	&__name{
		line-height: 40px;
		font-size: 32px;
		font-family: HYQiHeiY3-EEW, HYQiHeiY3;
		color: #FFFFFF;
		font-weight: normal;
	}
}

.fixed{
	text-align: right;
	font-size: 24px;
	padding: 0 20px 30px;
	color: #ffffff;
}
.nav_bar_colors{
	font-family: HYQiHeiY3-45;
	color: #ffffff !important;
	.van-nav-bar__title{
	color: #ffffff !important;
	}
	.van-nav-bar__text{
		color: #ffffff;
	}
	.van-nav-bar .van-icon {
		color: #ffffff;
	}
	.van-nav-bar{
		box-sizing: border-box;
		background-color: rgba(255, 255, 255, 1);
	} 
}

.isnav_bar_colors{
	font-family: HYQiHeiY3-45;
	background-color: rgba(255, 255, 255, 0);
	color: #ffffff !important;
	.van-nav-bar__title{
	color: #ffffff !important;
	}
	.van-nav-bar__text{
		color: #ffffff;
	}
	.van-nav-bar .van-icon {
		color: #ffffff;
	}
	.van-nav-bar{
		box-sizing: border-box;
		background-color: rgba(255, 255, 255, 0);
	} 
}
}

.bacg1{
	background: url(https://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/bg_home_head.png);
	background-size: 100% 100%;
	background-repeat: no-repeat
}
.bacg2{
	background: url(https://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/bg_home_head2.png);
	background-size: 100% 100%;
	background-repeat: no-repeat
}

.bagtop1{
	background: url(https://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/bg_home_top.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
}
.bagtop2{
	background: url(https://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/bg_home_top2.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
}
.bagtop3{
	font-family: HYQiHeiY3-45;
	background-color: #191919 !important;
	color: #ffffff !important;
	.van-nav-bar__title{
	color: #ffffff !important;
	}
	.van-nav-bar__text{
		color: #ffffff;
	}
	.van-nav-bar .van-icon {
		color: #ffffff;
	}
	.van-nav-bar{
		box-sizing: border-box;
		background-color: #191919 !important;
	} 
}
</style>
